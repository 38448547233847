import FontFaceObserver from "fontfaceobserver";

(function () {
    // Get existing cookies
    let cookies = document.cookie.split(";");
    cookies.forEach((el, i) => {
        var cookie = el.split("=");
        cookies[cookie[0].trim()] = cookie[1];
    });

    // Fonts, use https://meowni.ca/font-style-matcher/ to minimize the FOUT
    let fonts = [
        { name: "Open Sans", weight: 400 },
        { name: "Open Sans", weight: 400, style: "italic" },
        { name: "Open Sans", weight: 600 },
        { name: "Open Sans", weight: 700 },
        { name: "Open Sans", weight: 700, style: "italic" },
        { name: "Open Sans", weight: 800 },
        { name: "Philosopher", weight: 700 },
    ];

    // Set body attribute if fonts have already been loaded
    if (cookies["fontsLoaded"]) {
        document.body.setAttribute("data-fonts-loaded", "");
    } else {
        let promises = [];
        fonts.forEach((el) => {
            promises.push(
                new FontFaceObserver(el.name, {
                    weight: el.weight,
                    style: el.style ? el.style : "normal",
                }).load()
            );
        });
        Promise.all(promises).then(function () {
            document.body.setAttribute("data-fonts-loaded", "");
            document.cookie =
                "fontsLoaded=true; max-age=31536000; path=/; secure=true; samesite=strict";
        });
    }
})();
